<template>
	<div style="margin-bottom: 10px;">
		<el-card>
			<div>
				<el-input v-model="hostUrl" placeholder="请求域名"></el-input>
			</div>
			<div style="margin-top: 10px;">
				<el-input  v-model="mqttUrl" placeholder="mqtt地址"></el-input>
			</div>
		</el-card>
	</div>
	<div>
		<el-card>
			<div style="font-weight: 800; font-size: 14px; margin-bottom: 10px;">示例数据</div>
			<vue-json-editor ref="asd" @json-change="onJsonChange" v-model="jsonData" :showBtns="false" :mode="'code'"
				lang="zh" />
		</el-card>
	</div>
	<div>
		<el-card style="margin-top: 10px;">
			<div style="font-weight: 800; font-size: 14px; margin-bottom: 10px;">数据模板</div>
			<div>
				<el-button @click="setJSON(1)" type="primary" plain>主设备注册</el-button>
				<el-button @click="setJSON(2)" type="primary" plain>设备基础信息注册</el-button>
				<el-button @click="setJSON(3)" type="primary" plain>子设备注册</el-button>
				<el-button @click="setJSON(4)" type="primary" plain>信号报警</el-button>
				<el-button @click="setJSON(5)" type="primary" plain>传感器报警</el-button>
				<el-button @click="setJSON(6)" type="primary" plain>远程控制</el-button>
				<el-button @click="setJSON(7)" type="primary" plain>设备采集</el-button>
			</div>
		</el-card>
	</div>
	<div style="text-align: center; margin-top: 20px;">
		<el-button @click="postApi" type="primary">发送请求</el-button>
	</div>
</template>

<script>
	import axios from 'axios'
	import vueJsonEditor from 'vue-json-editor'
	export default {
		data() {
			return {
				jsonData: {
					tes: "213"
				},
				mqttUrl:"Topic-for-runiot-ctrl-system/server/867038034545998/send",
				hostUrl:"http://www.daruniot.com:8080"
			}
		},

		components: {
			vueJsonEditor
		},

		mounted() {
			this.$refs.asd.editor.set(this.jsonData)
		},

		methods: {
			postApi: function() {
				var that = this
				var data = {
					topic:that.mqttUrl,
					msgBody:that.jsonData
				}
				axios.post(this.hostUrl+"/mqtt/sendMsgToAll",data)
			},

			onJsonChange(value) {
				console.log('value:', value);
				this.jsonData = value
			},

			setJSON: function(index) {
				var json = {}
				switch (index) {
					case 1:
						json = {
							deviceSn: "867038034545998",
							subDeviceData: "9",
							subDeviceFenQu: "hello",
						}
						break
					case 2:
						json = {
							subDeviceTybe: "Z",
							deviceSn: "867038034545998",
							subDeviceId: "A4B4C1",
							subDeviceName: "2021-07-01 12:00:00",
							subDeviceData: "89xxxxxxxxxxxxxx",
							subDeviceData1: "0",
							subDeviceData2: "1",
							subDeviceDanWei: "12",
							subDeviceFenQu: "zhuce",
						}
						break
					case 3:
						json = {
							"subDeviceTybe": "A",
							"deviceSn": "867038034545998",
							"subDeviceId": "A1",
							"subDeviceName": "0",
							"subDeviceData": "0",
							"subDeviceData1": "50",
							"subDeviceData2": "10",
							"subDeviceDanWei": "^c",
							"subDeviceFenQu": "zhuce",
						}
						break
					case 4:
						json = {
							"deviceSn": "867038034545998",
							"subDeviceId": "A1",
							"subDeviceData": {
								dianliang: "dianliang",
								xinhao: "xinhao",
								id1: "id1",
								id2: "id2",
								jingdu: "jingdu",
								weidu: "weidu",
							},
							"subDeviceDanWei": "0",
							"subDeviceDataType": "OK",
							"subContent": "上限报警",
							"subDeviceFenQu": "log",
						}
						break
					case 5:
						json = {
							"deviceSn": "867038034545998",
							"subDeviceId": "C1",
							"subDeviceData": "23",
							"subDeviceDataType": "B",
							"subDeviceDanWei": "^c",
							"subContent": "上限",
							"subDeviceFenQu": "log",
						}
						break
					case 6:
						json = {
							"subDeviceTybe": "B",
							"deviceSn": "867038034545998",
							"subDeviceId": "B1",
							"subDeviceData": "0",
							"subDeviceData1": "0",
							"subDeviceData2": "0",
							"subDeviceFenQu": "ctrl",
						}
						break
					case 7:
						json = {
							"deviceSn":"867038034545998",
							"subDeviceId":"3",
							"subDeviceData":"23",
							"subDeviceDanWei":"^c",
							"subDeviceLogTable":"1",
							"subDeviceFenQu":"caiji",
						}
						break
				}
				
				this.$refs.asd.editor.set(json)
			}

		}
	}
</script>

<style>
	div.jsoneditor {
		border: none !important;
	}

	div.jsoneditor-menu {
		background-color: rgb(0, 21, 41);
	}

	.jsoneditor-poweredBy {
		display: none;
	}

	.jsoneditor-vue {
		box-shadow: 0 0 10px rgba(0, 0, 0, .2);
	}
</style>
